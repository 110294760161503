import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MetismenuAngularModule } from "@metismenu/angular";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNGModule } from './prime-module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgbDatepickerModule, NgbDropdownModule, NgbAlertModule, NgbToastModule, NgbButtonsModule, NgbTabsetModule, NgbAccordionModule, NgbModalModule, NgbProgressbarModule, NgbPopoverModule, NgbTooltipModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppServiceProvider, BusyCursorService} from 'src/app/service';
import { HttpDataService } from './service/common/http-data.service';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeModule } from 'primeng/tree';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListboxModule } from 'primeng/listbox';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ImageModule } from 'primeng/image';
import { TagModule } from 'primeng/tag';
import { UIModule } from 'src/app/shared/ui/ui.module';
import {DividerModule} from 'primeng/divider';
//import { NgxChartsModule }from '@swimlane/ngx-charts';
import {EditorModule} from 'primeng/editor';
import { RatingModule } from 'primeng/rating';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import { ChipModule } from 'primeng/chip';

//common
import { CityComponent } from 'src/app/pages/common/city/city.component';
import { CategoryComponent } from 'src/app/pages/common/category/category.component';
import { PushnotificationComponent } from 'src/app/pages/common/pushnotification/pushnotification.component';
import { AuthorsComponent } from 'src/app/pages/common/authors/authors.component';
import { LikesComponent } from 'src/app/pages/common/likes/likes.component';
import { ReviewComponent } from 'src/app/pages/common/review/review.component';
import { BlogCommentsComponent } from 'src/app/pages/common/blogcomments/blogcomments.component';
import { PostCommentsComponent } from 'src/app/pages/common/postcomments/postcomments.component';
import { PlaylistsComponent } from 'src/app/pages/common/playlists/playlists.component';
// import { BookmarksComponent } from 'src/app/pages/common/bookmarks/bookmarks.component';
import { TagsComponent } from 'src/app/pages/common/tags/tags.component';
import { TestimonialsComponent } from 'src/app/pages/common/testimonials/testimonials.component';
import { BannerComponent } from 'src/app/pages/common/banner/banner.component';

//admin
import { UserComponent } from 'src/app/pages/admin/user/user.component';
import { UserRolesComponent } from 'src/app/pages/admin/user-roles/user-roles.component';
import { AdminMenuComponent } from 'src/app/pages/admin/admin-menu/admin-menu.component';

// Masters
import { ProductMasterComponent } from 'src/app/pages/master/product-master/product-master.component';
import { ProductAttachmentsComponent } from 'src/app/pages/master/product-attachments/product-attachments.component';
import { BlogsComponent } from 'src/app/pages/master/blogs/blogs.component';
import { BlogAttachmentsComponent } from 'src/app/pages/master/blog-attachments/blog-attachments.component';
import { PostsComponent } from 'src/app/pages/master/posts/posts.component';
import { PostAttachmentsComponent } from 'src/app/pages/master/post-attachments/post-attachments.component';
import { BlogsetupComponent } from 'src/app/pages/master/blogsetup/blogsetup.component';
import { PostsetupComponent } from 'src/app/pages/master/postsetup/postsetup.component';
import { FilesharingComponent } from 'src/app/pages/master/filesharing/filesharing.component';
// import plugins from 'suneditor/src/plugins';
 import { NgxSuneditorModule } from "../../projects/ngx-suneditor/src/public-api";
//import { NgxSuneditorModule } from 'ngx-suneditor';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { EnquirylistComponent } from 'src/app/pages/common/enquirylist/enquirylist.component';

import { DocsharerequestComponent } from 'src/app/pages/common/docsharerequest/docsharerequest.component';

@NgModule({
  declarations: [
    AppComponent, UserComponent, UserRolesComponent, AdminMenuComponent,CityComponent, CategoryComponent, ProductMasterComponent, ProductAttachmentsComponent, BlogsComponent, PostsComponent, BlogAttachmentsComponent, PostAttachmentsComponent, AuthorsComponent,  LikesComponent, ReviewComponent, BlogCommentsComponent, PostCommentsComponent, PlaylistsComponent, TagsComponent, BlogsetupComponent, PostsetupComponent, TestimonialsComponent, BannerComponent, PushnotificationComponent, FilesharingComponent, EnquirylistComponent, DocsharerequestComponent],


  imports: [
    BrowserModule,
    CommonModule,
    MetismenuAngularModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    DropzoneModule,
    AppRoutingModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
    NgbToastModule,
    NgbTabsetModule,
    NgbButtonsModule,
    NgbNavModule,
    SplitButtonModule,
    InputNumberModule,
    NgbAccordionModule,
    ToastModule,
    FileUploadModule,
    TreeModule,
    InputMaskModule,
    ContextMenuModule,
    SelectButtonModule,
    ListboxModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTooltipModule,
    PrimeNGModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    MultiSelectModule,
    PickListModule,
    RadioButtonModule,
    ProgressBarModule,
    AvatarModule,
    AvatarGroupModule,
    ImageModule,
    TagModule,
    DividerModule,
   // NgxChartsModule,
    UIModule,
    EditorModule,
    RatingModule,
    OverlayPanelModule,
    GalleriaModule,
    DialogModule,
    ChipModule,
    CKEditorModule,
    NgxSuneditorModule.forRoot({
      // plugins: plugins,
      minWidth: '100%',
      height: '70vh',
      buttonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio'],
        ['fullScreen', 'showBlocks', 'codeView'],
        ['preview', 'print'],
        ['save'],
      ],
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [BusyCursorService,
    MessageService,
    AppServiceProvider,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpDataService, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
